
































































import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Ref } from 'vue-property-decorator'
import DraggableTreeview from 'vuetify-draggable-treeview'

import Confirmation from '@/components/modals/Confirmation.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import { ManualTreeResource } from '@/store/types'
import MobileManualModal from '@/components/modals/depositories/MobileManualModal.vue'

@Component({
  components: {
    ButtonTextIcon,
    Confirmation,
    DraggableTreeview,
    MobileManualModal,
  },
})
export default class DepositoryManualTree extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  private showMobileManualModal = false

  private get createText() {
    return this.manualTree && this.manualTree.length ? 'Создать документ' : 'Создайте первый документ'
  }

  private get manualTree () {
    return ManagerDepositoryModule.manualTree
  }

  private set manualTree (value: ManualTreeResource[]) {
    ManagerDepositoryModule.setManualTree(value)
  }

  private get isTreePage () {
    return this.$route.name === 'manager.bank.depository.manual'
  }

  private get currentManual() {
    return ManagerDepositoryModule.manual
  }

  private get breadcrumbs () {
    return [{ name: 'База знаний', path: '/manager/bank/depository' }]
  }

  private mounted () {
    ManagerDepositoryModule.setManual(null)
    if (this.isTreePage || this.manualTree.length === 0) {
      ManagerDepositoryModule.fetchManualTree()
        .catch(this.notifyError)
    }
  }

  private handleChangeTree (tree: any) {
    ManagerDepositoryModule.saveManualTree(tree)
      .catch(this.notifyError)
  }

  private handleCreate (id: number) {
    this.$router.push({
      name: 'manager.bank.depository.manual.new',
      query: { parentID: id.toString() },
    })
  }

  private handleDelete (id: number) {
    ManagerDepositoryModule.deleteManual(id)
      .then(() => {
        this.notifySuccess('Документ удален')
        this.$router.push({ name: 'manager.bank.depository.manual' })
        ManagerDepositoryModule.fetchManualTree()
        if (this.$route.name === 'manager.bank.depository.manual.item' && this.$route.params.manualID === id.toString()) {
          this.$router.replace({ name: 'manager.bank.depository.manual' })
        }
      })
      .catch(this.notifyError)
  }

  private confirmDelete (id: number) {
    this.confirm.open(
      'Подтверждение действия',
      'Вы уверены, что хотите удалить документ?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        this.handleDelete(id)
      })
      .catch(() => {return})
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Методичка наставника',
    }
  }
}
