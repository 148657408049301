var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDialog',{style:({ zIndex: 990 }),attrs:{"value":_vm.visible,"fullscreen":true,"persistent":true},on:{"input":_vm.handleClose}},[_c('VCard',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header__title text-body-2"},[_vm._v("Оглавление")]),_c('div',{staticClass:"header__close"},[_c('VBtn',{attrs:{"color":"grey","icon":""},on:{"click":_vm.handleClose}},[_c('VIcon',[_vm._v("$close")])],1)],1)]),_c('div',{staticClass:"content"},[_c('Vuescroll',{ref:"information"},[_c('VTreeview',{attrs:{"items":_vm.folders},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'manager.bank.depository.manual.item', params: { manualID: item.id } }},nativeOn:{"click":function($event){return _vm.cleanup($event)}}},[_vm._v(_vm._s(item.title))])]}},(_vm.hasPermission(_vm.Permission.DEPOSITORY_MANUAL_EDIT))?{key:"append",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [[_c('VMenu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',{on:{"click":function($event){return _vm.handleCreate(item.id)}}},[_c('VListItemTitle',[_vm._v("Создать")])],1),_c('VListItem',{on:{"click":function($event){return _vm.confirmDelete(item.id)}}},[_c('VListItemTitle',[_vm._v("Удалить")])],1)],1)],1)]]}}:null],null,true)})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }